import {gql} from 'graphql-request'

// GraphQL queries

export const ALL_PAGES = gql`
    query pages($archived: Boolean, $order_by: String, $order_dir: OrderDir, $site_id: ID!, $skip: Float, $take: Float) {
        pages(
            archived: $archived
            order_by: $order_by
            order_dir: $order_dir
            site_id: $site_id
            skip: $skip
            take: $take
        ) {
            live_page_build_id
            dev_page_build_id
            name
            site_id
            archived
            dev_page_build_id
            created_at
            updated_at
            attributes {
                page_tags
            }
            route {
                created_at
                updated_at
                id
                url
                page_id
                page {
                    id
                    name
                }
                route_type
                route_aliases {
                    id
                    url
                    route_type
                    target_route_id
                }
            }
            attributes {
                page_tags
            }
            page_builds {
                ...PageBuildFragment
            }
            id
            live_page_build_id
            live_page_build {
                ...PageBuildFragment
            }
            dev_page_build {
                ...PageBuildFragment
            }
        }
    }

    fragment PageBuildFragment on PageBuild {
        id
        assigned_to_user_id
        created_at
        updated_at
        description
        page_id
        last_deployment_date
        published_date
        source_page_build_id
        status
        updated_at
        assigned_to_user {
            id
            name
        }
    }
`

export const ALL_ROLES = gql`
    {
        roles {
            id
            description
            name
            created_at
        }
    }
`

export const SEARCH_USERS = gql`
    query searchUsers(
        $order_by: String
        $order_dir: OrderDir
        $searchUserInput: SearchUserInput!
        $site_id: ID
        $skip: Float
        $take: Float
    ) {
        searchUsers(
            order_by: $order_by
            order_dir: $order_dir
            searchUserInput: $searchUserInput
            site_id: $site_id
            skip: $skip
            take: $take
        ) {
            created_at
            email
            company
            id
            name
            role {
                name
                id
                description
            }
            role_id
            updated_at
        }
    }
`

export const ALL_USERS = gql`
    query users($skip: Float, $take: Float) {
        users(skip: $skip, take: $take) {
            id
            email
            name
            company
            created_at
            role_id
            role {
                name
                description
                id
            }
            site_users {
                site {
                    id
                    name
                    domain
                    client_account
                }
            }
        }
    }
`

export const TEMPLATE_MENU_ITEMS = gql`
    query ($site_build_id: ID!) {
        templateMenuItems(side_build_id: $site_build_id) {
            id
            label
            sort_order
            site_build_id
            route_id
            created_at
            updated_at
            route {
                route_type
                id
                url
            }
            group_menu_items {
                site_build_id
                label
                sort_order
                route {
                    route_type
                    id
                    url
                }
            }
        }
    }
`

export const PAGE_BUILD = gql`
    query pageBuild($id: ID!) {
        pageBuild(id: $id) {
            assigned_to_user_id
            source_page_build_id
            status
            updated_at
            created_at
            description
            id
            page_id
            last_deployment_date
            published_date
            assigned_to_user {
                id
                attributes {
                    preferred_name
                }
                name
                email
                role {
                    name
                    id
                }
            }
            page {
                name
                live_page_build_id
                dev_page_build_id
                site_id
                route {
                    ...RouteFragment
                }
            }
        }
    }

    fragment RouteFragment on Route {
        id
        page_id
        url
    }
`

export const PAGE_QUERY = gql`
    query page($id: ID!) {
        page(id: $id) {
            archived
            attributes {
                ...PageAttributesFragment
            }
            created_at
            dev_page_build {
                ...PageBuildFragment
            }
            dev_page_build_id
            id
            live_page_build {
                ...PageBuildFragment
            }
            live_page_build_id
            name
            description
            image
            twitter_username
            bar_color_scheme
            consultation_phone_number
            ppc_phone_number
            route {
                ...RouteFragment
            }
            site_id
            updated_at
        }
    }
    fragment PageAttributesFragment on PageAttributes {
        exclude_from_sitemap
        page_tags
    }
    fragment PageBuildFragment on PageBuild {
        # assigned_to_user {}
        assigned_to_user_id
        created_at
        description
        id
        page_id
        source_page_build_id
        status
        updated_at
    }
    fragment RouteFragment on Route {
        id
        page_id
        url
    }
`

export const SITES_MANAGER_QUERY = gql`
    query sites(
        $archived: Boolean
        $order_by: String
        $order_dir: OrderDir
        $skip: Float
        $take: Float
    ) {
        sites(
            archived: $archived
            order_by: $order_by
            order_dir: $order_dir
            skip: $skip
            take: $take
        ) {
            archived
            client_account
            client_site
            created_at
            domain
            secondary_domains
            id
            launch_date
            name
            updated_at
        }
    }
`

export const SITE_QUERY = gql`
    query site($id: ID!) {
        site(id: $id) {
            name
            id
            client_account
            domain
            secondary_domains
            launch_date
            archived
            client_site
            created_at
            updated_at
            live_site_build_id
            dev_site_build_id
            contents {
                id
                name
                value
                updated_at
            }
            live_site_build {
                id
                updated_at
                typekit_kits
            }
            dev_site_build {
                id
                updated_at
                typekit_kits
            }
            proxy_configs {
                id
                proxy_config_id
                last_pipeline_execution_id
                updated_at
                config_status
                site_build_version
                page_build_version
            }
        }
    }
`

export const SITE_FROM_DOMAIN_QUERY = gql`
    query siteFromDomain($domain: String!) {
        siteFromDomain(domain: $domain) {
            name
            id
            client_account
            domain
            secondary_domains
            launch_date
            client_site
            created_at
            updated_at
            live_site_build_id
            dev_site_build_id
            pages {
                id
                name
                route {
                    id
                    url
                    route_type
                }
                created_at
                updated_at
            }
        }
    }
`

export const ALL_SECTIONS = gql`
    query sections(
        $archived: Boolean
        $order_by: String
        $order_dir: OrderDir
        $skip: Float
        $take: Float
    ) {
        sections(
            archived: $archived
            order_by: $order_by
            order_dir: $order_dir
            skip: $skip
            take: $take
        ) {
            archived
            status
            attributes {
                render_on_page
            }
            created_at
            id
            name
            description
            preview_photo
            section_fields {
                created_at
                default_value
                id
                input_type
                name
                section_id
                sort_order
                updated_at
                attributes {
                    label
                    value
                }
            }
            updated_at
        }
    }
`

export const PAGE_SECTIONS_QUERY = gql`
    query pageSections(
        $page_build_id: ID!
    ) {
        pageSections(
            page_build_id: $page_build_id
        ) {
          contents {
              id
              name
              value
          }
          created_at
          id
          page_build_id
          page_section_type
          color_scheme_id
          section {
              id
              name
              status
          }
          section_id
          sort_order
          updated_at
      }
  }
`

export const CONTENTS = gql`
    query contents(
        $order_by: String
        $order_dir: OrderDir
        $page_section_id: ID
        $site_id: ID
        $skip: Float
        $take: Float
    ) {
        contents(
            order_by: $order_by
            order_dir: $order_dir
            page_section_id: $page_section_id
            site_id: $site_id
            skip: $skip
            take: $take
        ) {
            created_at
            id
            name
            page_section_id
            scope
            site_id
            updated_at
            value
        }
    }
`

/**
 * Queries for Color Schemes
 */

export const SITE_BUILD_COLOR_SCHEMES = gql`
    query($site_build_id: ID!) {
        colorSchemes(
            site_build_id: $site_build_id
        ) {
            id
            active
            site_build_id
            name
            components {
                id
                label
                type
                component_content {
                    hex
                    range
                    URL
                    checkbox
                    SVG
                }
            }
            parent_color_scheme_id
            children_color_schemes {
                id

                name
                components {
                    id
                    label
                    type
                    component_content {
                        hex
                        range
                        URL
                        checkbox
                        SVG
                    }
                }
                parent_color_scheme_id
            }
        }
    }
`

export const UPDATE_COLOR_SCHEME = gql`
    mutation($color_scheme_id: ID!, $name: String!) {
        updateColorScheme(update:{
            color_scheme_id: $color_scheme_id
            name: $name
        }){
            id
        }
    }
`

export const MENUS = gql`
    query($site_build_id: ID!) {
        menus(site_build_id: $site_build_id) {
            id
            name
            archived
            source_menu_id
            created_at
            updated_at
            menu_items {
                id
                label
                site_build_id
                parent_menu_item_id
                group_menu_items {
                    id
                    label
                    site_build_id
                    parent_menu_item_id
                    sort_order
                    menu_id
                    created_at
                    updated_at
                    route {
                        id
                        created_at
                        updated_at
                        route_type
                        page_id
                        url
                    }
                }
                sort_order
                menu_id
                created_at
                updated_at
                route {
                    id
                    created_at
                    updated_at
                    route_type
                    page_id
                    url
                }
            }
        }
    }
`

export const GET_SITE_BUILD_HEADER_AND_FOOTER_SECTIONS = gql`
    query($id: ID!) {
        siteBuild(id: $id) {
            id
            header_sections {
                ...Section
            }
            footer_sections {
                ...Section
            }
        }
    }
    fragment Section on PageSection {
        contents {
            id
            name
            value
        }
        created_at
        id
        page_build_id
        site_build_id
        color_scheme_id
        page_section_type
        section {
            id
            name
            status
        }
        section_id
        sort_order
        updated_at
    }
`

export const PUBLISHED_PATHS = gql`
    {
        publishedPaths {
            slug
            proxy_config_id
        }
    }
`

export const PAGE_DEFINITION = gql`
    query getPageDefinition($slug: String!, $proxy_config_id: String!) {
        pageDefinition(slug: $slug, proxy_config_id: $proxy_config_id) {
            domain
            site_id
            page_id
            slug
            proxy_config_id
            site_build_version
            page_build_version
        }
    }
`

export const GET_SITE_BUILD_TYPOGRAPHY = gql`
    query($site_build_id: ID!){
        typographies(site_build_id: $site_build_id){
            id,
            name,
            description,
            is_default,
            components{
              id,
              name,
              selectors,
              primary_font_family,
              fallback_font_family,
              options {
                label,
                value
              }
            }
        }
    }
`

export const GET_SITE_BUILD_TYPEKIT_KITS = gql`
    query($site_build_id: ID!){
      siteBuild(id: $site_build_id){
        id,
        typekit_kits
      }
    }
`

export const GET_SITE_BUILD_PLUGINS = gql`
    query($site_build_id: ID!){
        plugins(site_build_id: $site_build_id){
            id
            name
            plugin_type
            url_path
            created_at
            updated_at
            route {
                id
                url
                route_type
                route_aliases {
                    id
                    url
                    route_type
                    target_route_id
                }
            }
            active
        }
    }
`
