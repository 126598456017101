import {useMemo} from 'react'

export const useTypeKitFonts = (
  typekitKitIds: string[],
): void => {
  useMemo(() => {
    typekitKitIds?.map((kitId) => {
      const href = `https://use.typekit.net/${kitId}.css`
      const existingLink = document.querySelector(`link[href="${href}"]`) as HTMLLinkElement

      if (!existingLink) {
        const link: HTMLLinkElement = document.createElement('link')
        link.href = href
        link.rel = 'stylesheet'
        document.head.appendChild(link)
      }
    })
  }, [typekitKitIds])
}
