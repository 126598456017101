import React, {useEffect, useState} from 'react'
import type {StyleCoreRenderedMap, StyleCoreTarget} from '@/components/shared/StyleCore'
import type {SectionMeta} from '@/components/shared/types'
import type {StyleCoreCSSComponentProps} from '@/components/sections/shared/CMSSection'

type StyleManagerProps = {
  cms: boolean,
  meta: SectionMeta,
  styleCoreTarget: StyleCoreTarget,
  prefixCss: string | undefined,
  prerenderedStyles: StyleCoreRenderedMap
}


export function prependClassNameToCSS(cn: string, cms: boolean, css_overrides?: string): string {
  if (!css_overrides) {
    return ''
  }
  const lines = css_overrides.split('\n')
  const updatedLines: string[] = []
  let insideAtRule = false
  let atRuleContent = ''

  for (const line of lines) {
    const trimmedLine = line.trim()

    if (trimmedLine.startsWith('@')) {
      // Handle @ rules (e.g., @media, @keyframes)
      updatedLines.push(trimmedLine)
      insideAtRule = true
      atRuleContent = ''
    } else if (insideAtRule) {
      // Inside an @ rule, accumulate the content
      atRuleContent += line + '\n'

      if (trimmedLine.endsWith('}')) {
        // End of the @ rule content
        insideAtRule = false

        // Process the @ rule content and prepend the class name
        const updatedAtRuleContent = prependClassNameToCSS(cn, cms, atRuleContent)
        updatedLines.push(updatedAtRuleContent)
      }
    } else if (trimmedLine) {
      // Handle regular CSS rules
      const parts = trimmedLine.split('{')
      if (parts.length === 2) {
        const selectors = parts[0].split(',')
        const rules = parts[1]
        const updatedSelectors = selectors.map(selector => {
          // Trim each selector and prepend the class name
          const trimmedSelector = selector.trim()
          const space = trimmedSelector.trimStart().startsWith(':') ? '' : ' '
          return `.${cn}${space}${trimmedSelector}`
        })
        updatedLines.push(`${updatedSelectors.join(', ')} {${rules}`)
      } else {
        if (cms && (process.env.NEXT_PUBLIC_MUTE_WARNINGS !== 'true')) {
          console.warn(`Invalid CSS rule at ${line}. Skipping...`)
        }
        // Invalid CSS rule, just push it as is
        updatedLines.push(line)
      }
    }
  }

  return updatedLines.join('\n')
}
type SectionEditorLoader = { StyleCoreCSSComponent: null | React.FunctionComponent<StyleCoreCSSComponentProps> }

const StyleManager = ({cms, meta, styleCoreTarget, prefixCss, prerenderedStyles}: StyleManagerProps) => {

  const [styleCoreComponent, setStyleCoreComponent] = useState<SectionEditorLoader>({
    StyleCoreCSSComponent: null
  })

  useEffect(() => {
    const loadStyleCoreCSSComponent = async () => {
      const {StyleCoreCSSComponent} = await import('./CMSSection')
      setStyleCoreComponent({StyleCoreCSSComponent})
    }
    if (cms) {
      loadStyleCoreCSSComponent()
    }
  }, [])

  if (styleCoreComponent.StyleCoreCSSComponent) {
    return <>
      <styleCoreComponent.StyleCoreCSSComponent target={styleCoreTarget} cms={cms}/>
      <style jsx global>{`${prefixCss ?? ''}`}</style>
    </>
  } else {
    const mapElement = prerenderedStyles.get(styleCoreTarget?.identifier ?? '')
    let styles = meta?.style?.css
    if (typeof mapElement !== 'undefined') {
      styles = mapElement.css
    }
    return <>
      <style jsx global>{`${prefixCss ?? ''} ${styles ?? ''}`}</style>
    </>
  }
}

export default StyleManager
