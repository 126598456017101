import {gql} from 'graphql-request'

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $updateUserData: UpdateUserInput!) {
    updateUser(id: $id, updateUserData: $updateUserData) {
      id
      email
      company
      name
      role {
        id
        description
        name
      }
    }
  }
`
export const CREATE_USER = gql`
  mutation addUser($newUser: NewUserInput!) {
    addUser(newUser: $newUser) {
      id
      email
      company
      name
      role {
        id
        description
        name
      }
    }
  }
`

/*
 *
 *   Roles
 * */
export const UPDATE_ROLE = gql`
  mutation updateRole($id: ID!, $updateRoleData: UpdateRoleInput!) {
    updateRole(id: $id, updateRoleData: $updateRoleData) {
      id
      description
      name
    }
  }
`

export const CREATE_ROLE = gql`
  mutation addRole($newRole: NewRoleInput!) {
    addRole(newRole: $newRole) {
      id
      description
      name
    }
  }
`

/*
 *
 *   Pages
 * */

export const ADD_PAGE = gql`
  mutation addPage($newPage: NewPageInput!) {
    addPage(newPage: $newPage) {
      dev_page_build_id
      page_builds {
        assigned_to_user_id
        created_at
        description
        id
        page_id
        source_page_build_id
        status
        updated_at
      }
      id
      live_page_build_id
      name
      site_id
      site {
        name
        id
      }
    }
  }
`

export const CLONE_PAGE_BUILD = gql`
  mutation clonePageBuild($source_page_build_id: ID!) {
    clonePageBuild(source_page_build_id: $source_page_build_id)
  }
`

export const ARCHIVE_PAGE_V2 = gql`
  mutation archivePage($id: ID!) {
    archivePage(id: $id)
  }
`

export const UPDATE_PAGE = gql`
  mutation updatePage($id: ID!, $updatePageData: UpdatePageInput!) {
    updatePage(id: $id, updatePageData: $updatePageData) {
      archived
      created_at
      dev_page_build_id
      id
      live_page_build_id
      name
      site_id
      updated_at
    }
  }
`

export const SET_PAGE_BUILD_STATUS = gql`
  mutation setPageBuildStatus($page_build_id: ID!, $status: PageBuildStatus!) {
    setPageBuildStatus(page_build_id: $page_build_id, status: $status)
  }
`

export const ASSIGN_PAGE_BUILD_TO_USER = gql`
  mutation assignPageBuildToUser($page_build_id: ID!, $user_id: ID!) {
    assignPageBuildToUser(page_build_id: $page_build_id, user_id: $user_id)
  }
`

export const UNASSIGN_PAGE_BUILD_USER = gql`
    mutation unassignPageBuildUser($page_build_id: ID!) {
        unassignPageBuildUser(page_build_id: $page_build_id)
    }
`

export const PUBLISH_PAGE_BUILD = gql`
  mutation publishPageBuild($page_id: ID!, $site_build_version: ProxyConfigVersion!, $page_build_version: ProxyConfigVersion!) {
      publishPageBuild(page_id: $page_id, site_build_version: $site_build_version, page_build_version: $page_build_version) {
          success
          message
      }
  }
`

export const PUBLISH_SITE_BUILD = gql`
    mutation publishSiteBuild($site_id: ID!, $site_build_version: ProxyConfigVersion!, $page_build_version: ProxyConfigVersion!) {
        publishSiteBuild(site_id: $site_id, site_build_version: $site_build_version, page_build_version: $page_build_version) {
            success
            message
        }
    }
`

export const UPDATE_PAGE_V2 = gql`
  mutation updatePage($id: ID!, $updatePageData: UpdatePageInput!) {
    updatePage(id: $id, updatePageData: $updatePageData) {
      name
      archived
      created_at
      dev_page_build_id
      id
      live_page_build_id
      site_id
      updated_at
      description
      image
      twitter_username
      bar_color_scheme
      consultation_phone_number
      ppc_phone_number
    }
  }
`

/**
 *  Routes
 */

export const ADD_ROUTE = gql`
  mutation addRoute($newRoute: NewRouteInput!) {
    addRoute(newRoute: $newRoute) {
      created_at
      id
      # page {}
      page_id
      route_aliases {
        id
      }
      route_type
      # target_route {}
      target_route_id
      updated_at
      url
    }
  }
`

export const UPDATE_ROUTE = gql`
  mutation updateRoute($id: ID!, $updateRouteData: UpdateRouteInput!) {
    updateRoute(id: $id, updateRouteData: $updateRouteData) {
      created_at
      id
      # page {}
      page_id
      route_aliases {
        id
      }
      route_type
      # target_route {}
      target_route_id
      updated_at
      url
    }
  }
`

export const REMOVE_ROUTE = gql`
  mutation removeRoute($id: ID!) {
    removeRoute(id: $id)
  }
`

export const CREATE_SITE_MUTATION = gql`
  mutation addSite($newSite: NewSiteInput!) {
    addSite(newSite: $newSite) {
      id
      created_at
      updated_at
      name
      domain
      secondary_domains
      client_account
      client_site
      archived
      launch_date
    }
  }
`

export const UPDATE_SITE_MUTATION = gql`
  mutation updateSite($id: ID!, $updateSiteData: UpdateSiteInput!) {
    updateSite(id: $id, updateSiteData: $updateSiteData) {
      archived
      client_account
      client_site
      created_at
      domain
      secondary_domains
      id
      launch_date
      name
      updated_at
    }
  }
`

export const ARCHIVE_SITE_MUTATION = gql`
  mutation archiveSite($id: ID!) {
    archiveSite(id: $id)
  }
`

export const ADD_SITE_USER = gql`
  mutation addSiteUser($site_id: ID!, $user_id: ID!) {
    addSiteUser(site_id: $site_id, user_id: $user_id) {
      id
      site_id
      user_id
    }
  }
`

export const REMOVE_SITE_USER = gql`
  mutation removeSiteUser($site_id: ID!, $user_id: ID!) {
    removeSiteUser(site_id: $site_id, user_id: $user_id)
  }
`

export const REMOVE_USER = gql`
  mutation removeUser($user_id: ID!) {
    removeUser(id: $user_id)
  }
`

export const ADD_SECTION = gql`
  mutation addSection($newSection: NewSectionInput!) {
    addSection(newSection: $newSection) {
      id
      name
      status
      description
      preview_photo
      created_at
      updated_at
    }
  }
`

export const ARCHIVE_SECTION = gql`
  mutation archiveSection($id: ID!) {
    archiveSection(id: $id)
  }
`

export const UPDATE_SECTION = gql`
  mutation updateSection($id: ID!, $updateSectionData: UpdateSectionInput!) {
    updateSection(id: $id, updateSectionData: $updateSectionData) {
      id
      name
      status
      description
      preview_photo
      created_at
      updated_at
    }
  }
`

export const UPDATE_PAGE_BUILD = gql`
  mutation updatePageBuild(
    $id: ID!
    $updatePageBuildData: UpdatePageBuildInput!
  ) {
    updatePageBuild(id: $id, updatePageBuildData: $updatePageBuildData) {
      # assigned_to_user {
      #   ...UserFragment
      # }
      assigned_to_user_id
      body_sections {
        contents {
          id
          name
          value
        }
        created_at
        id
        page_build_id
        section {
          id
          name
        }
        section_id
        sort_order
        updated_at
      }
      created_at
      description
      id
      # page {
      #   ...PageFragment
      # }
      page_id
      # source_page_build {
      #   ...PageBuildFragment
      # }
      source_page_build_id
      status
      updated_at
    }
  }
`

export const SET_LIVE_PAGE_BUILD = gql`
  mutation setLivePageBuild($page_build_id: ID!, $page_id: ID!) {
    setLivePageBuild(page_build_id: $page_build_id, page_id: $page_id)
  }
`

export const SET_DEV_PAGE_BUILD = gql`
    mutation setDevPageBuild($page_build_id: ID!, $page_id: ID!) {
        setDevPageBuild(page_build_id: $page_build_id, page_id: $page_id)
    }
`

export const ADD_CONTENT = gql`
  mutation addContent($newContent: NewContentInput!) {
    addContent(newContent: $newContent) {
      id
    }
  }
`

export const UPDATE_CONTENT = gql`
  mutation updateContent($id: ID!, $updateContentData: UpdateContentInput!) {
    updateContent(id: $id, updateContentData: $updateContentData) {
      id
    }
  }
`

export const ADD_PAGE_BUILD = gql`
  mutation($id: ID!, $description: String!) {
    addPageBuild(newPageBuild: {
      page_id: $id,
      description: $description
    }) {
      id
    }
  }
`

export const CLONE_SITE_BUILD = gql`
  mutation($source_site_build_id: ID!) {
    cloneSiteBuild(source_site_build_id: $source_site_build_id)
  }
`

export const SET_LIVE_SITE_BUILD = gql`
  mutation($site_build_id: ID!, $site_id: ID!) {
    setLiveSiteBuild(site_build_id: $site_build_id, site_id: $site_id)
  }
`

export const SET_DEV_SITE_BUILD = gql`
  mutation($site_build_id: ID!, $site_id: ID!) {
    setDevSiteBuild(site_build_id: $site_build_id, site_id: $site_id)
  }

`

export const ADD_SITE_BUILD = gql`
  mutation($newSiteBuild: NewSiteBuildInput!) {
    addSiteBuild(newSiteBuild: $newSiteBuild) {
      id
    }
  }
`

export const UPDATE_SITE_BUILD = gql`
  mutation($id: ID!, $updateSiteBuildData: UpdateSiteBuildInput!) {
    updateSiteBuild(id: $id, updateSiteBuildData: $updateSiteBuildData) {
      name
      description
      stage
      header_sections {
        ...HeaderFooterFragment
      }
      footer_sections {
        ...HeaderFooterFragment
      }
    }
  }

  fragment HeaderFooterFragment on PageSection {
    contents {
      id
      name
      value
    }
    created_at
    id
    page_build_id
    section {
      id
      name
    }
    section_id
    sort_order
    updated_at
  }
`

export const UPDATE_COLOR_SCHEME = gql`
    mutation($color_scheme_id: ID!, $name: String!) {
      updateColorScheme(update:{
        color_scheme_id: $color_scheme_id
        name: $name
      }){
        id
      }
    }
`

export const SET_ACTIVE_COLOR_SCHEME = gql`
    mutation($color_scheme_id: ID!) {
        setActiveColorScheme(color_scheme_id: $color_scheme_id) {
            id
            active
        }
    }
`

export const UPDATE_COLOR_SCHEME_COMPONENT = gql`
    mutation($color_scheme_id: ID!, $component_id: ID!, $component_content: ColorSchemeComponentContentInput!) {
        updateColorSchemeComponent(update:{
            color_scheme_id: $color_scheme_id,
            component_id: $component_id,
            component_content: $component_content
        }){
            id
        }
    }
`

export const ADD_COLOR_SCHEME = gql`
mutation ($color_scheme_content: NewColorSchemeInput!) {
  addColorScheme(newColorScheme: $color_scheme_content) {
    id
    children_color_schemes {
      id
    }
  }
}
`

export const REMOVE_COLOR_SCHEME = gql`
mutation ($color_scheme_id: ID!) {
  removeColorScheme(color_scheme_id: $color_scheme_id)
}
`

export const ADD_MENU = gql`
  mutation ($new_menu_input: NewMenuInput!) {
    addMenu(newMenu: $new_menu_input) {
      id
      name
      site_build_id
      created_at
      updated_at
      archived
    }
  }
`

export const UPDATE_MENU = gql`
  mutation ($id: ID!, $update_menu_input: UpdateMenuInput!) {
    updateMenu(id: $id, updateMenuData: $update_menu_input) {
      name
      menu_items {
        label
        sort_order
      }
    }
  }
`

export const GROUP_TEMPLATE_MENU_ITEM = gql`
    mutation ($site_build_id: ID!, $label: String!) {
        addGroupTemplateMenuItem(addGroupMenuItem: {
            site_build_id: $site_build_id
            label: $label
            sort_order: 0
        }) {
            id
        }
    }
`

export const ARCHIVE_MENU = gql`
  mutation($id: ID!) {
    archiveMenu(id: $id)
  }
`

// Destructive
export const ADD_MENU_ITEMS = gql`
  mutation ($add_menu_items_input: AddMenuItemsInput!) {
    addMenuItems(addMenuItem: $add_menu_items_input) {
      id
      menu_items {
        id
      }
    }
  }
`

export const ADD_MENU_ITEM = gql`
  mutation($add_menu_item_input: AddMenuItemInput!) {
    addMenuItem(addMenuItem: $add_menu_item_input) {
      id
    }
  }
`

export const UPDATE_MENU_ITEM = gql`
  mutation($id: ID!, $update_menu_item_input: UpdateMenuItemInput!) {
    updateMenuItem(menu_item_id: $id, updateMenuItem: $update_menu_item_input) {
      id
    }
  }
`

export const REMOVE_MENU_ITEM = gql`
  mutation($id: ID!) {
    removeMenuItem(menu_item_id: $id)
  }
`

export const SET_ACTIVE_PAGE_SECTION_COLOR_SCHEME = gql`
    mutation($page_section_id: ID!, $color_scheme_id: ID!) {
        setPageSectionColorScheme(page_section_id: $page_section_id, color_scheme_id: $color_scheme_id)
    }
`

export const UNSET_ACTIVE_PAGE_SECTION_COLOR_SCHEME = gql`
    mutation($page_section_id: ID!) {
        unsetPageSectionColorScheme(page_section_id: $page_section_id)
    }
`

export const UPDATE_PAGE_SECTION = gql`
    mutation($id: ID!, $update_page_section_input: UpdatePageSectionInput!){
      updatePageSection(id: $id, updatePageSectionData: $update_page_section_input){
        id
      }
    }
`

export const REMOVE_PAGE_SECTION = gql`
    mutation($id: ID!){
      removePageSection(id: $id)
    }
`

export const ADD_PAGE_SECTION = gql`
    mutation($new_page_section_input: NewPageSectionInput!){
      addPageSection(newPageSection: $new_page_section_input){
        id
      }
    }
`

export const UPDATE_TYPOGRAPHY = gql`
    mutation($id: ID!, $update_typography_input: UpdateTypographyInput!){
        updateTypography(id: $id, updateTypographyData: $update_typography_input){
            id
        }
    }
`

export const ADD_TYPOGRAPHY = gql`
    mutation($new_typography_input: NewTypographyInput!){
        addTypography(newTypography: $new_typography_input){
            id
        }
    }
`

export const DELETE_TYPOGRAPHY = gql`
    mutation($id: ID!){
        deleteTypography(id: $id)
    }
`

export const UPDATE_SITE_BUILD_TYPEKIT_KITS = gql`
    mutation($site_build_id: ID!, $typekit_kits: [String!]!){
        updateTypekitKits(site_build_id: $site_build_id, typekit_kits: $typekit_kits) {
          typekit_kits
        }
    }
`

export const ADD_PLUGIN = gql`
    mutation($new_plugin_input: NewPluginInput!){
        addPlugin(newPlugin: $new_plugin_input){
            id
        }
    }
`

export const UPDATE_PLUGIN = gql`
    mutation($id: ID!, $update_plugin_input: UpdatePluginInput!){
        updatePlugin(id: $id, updatePluginData: $update_plugin_input){
            id
        }
    }
`

export const REMOVE_PLUGIN = gql`
    mutation($id: ID!){
        removePlugin(id: $id)
    }
`


export const SITE_BUILD_NEEDS_SYNC = gql`
    query($site_build_version: ProxyConfigVersion!, $page_build_version: ProxyConfigVersion!, $site_id: ID!) {
        siteBuildNeedsProxySync(
            site_build_version: $site_build_version, page_build_version: $page_build_version, site_id: $site_id
        )
    }
`

export const UPSERT_PROXY_CONFIG = gql`
    mutation ($page_build_version: ProxyConfigVersion!, $site_build_version: ProxyConfigVersion!, $site_id: ID!) {
        upsertProxyConfig(
            page_build_version: $page_build_version, site_build_version: $site_build_version, site_id: $site_id
        ) {
            success
            message
        }
    }
`
